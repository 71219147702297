<template>
  <b-container>
    <b-card>
      <b-form>
        <validation-observer ref="observer">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Banco"
                label-for="banco"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Banco"
                  rules="required"
                >
                  <b-form-select
                    id="banco"
                    v-model="request.bank"
                    :state="errors.length > 0 ? false : null"
                    :options="banksOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Conta"
                label-for="conta"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Conta"
                >
                  <b-form-select
                    id="conta"
                    v-model="request.account"
                    :state="errors.length > 0 ? false : null"
                    :options="accountsOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Arquivo"
                label-for="arquivo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Arquivo"
                  :rules="`required|ofx`"
                >
                  <b-form-file
                    id="arquivo"
                    v-model="request.file"
                    browse-text="Procurar"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Arraste ou selecione um arquivo"
                    drop-placeholder="Arraste o arquivo aqui."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            variant="primary"
            @click="uploadFile"
          >
            Enviar
          </b-button>
        </validation-observer>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
    BRow, BForm, BFormFile, BCol, BFormGroup, BFormSelect, BCard, BContainer, BButton,
} from 'bootstrap-vue';

import OfxService from '@/service/conciliation/ofx';

import { callLoading } from '@/service/utilities';

import BankService from '@/service/bank';

import GetBankMixin from '@/mixins/banks/GetBanks';
import AccountBankMixin from '@/mixins/banks/AccountOptions';
import BankMixin from '@/mixins/banks/BankOptions';

import '@validations';

export default {
    components: {
        BButton, ValidationObserver, ValidationProvider, BRow, BForm, BFormFile, BCol, BFormGroup, BFormSelect, BCard, BContainer,
    },

    mixins: [BankMixin, GetBankMixin, AccountBankMixin],

    data() {
        return {
            request: {
                username: this.$store.state.user.data.name,
                bank: null,
                account: null,
                file: null,
            },
            banks: null,
        };
    },

    computed: {
        accountsOptions() {
            return this.doAccountOptions(this.banks, this.request.bank);
        },

        banksOptions() {
            if (this.banks) { return this.doBankOptions(this.banks); }

            return [];
        },
    },

    watch: {
        'request.bank': function handleBankChange() {
            this.request.account = null;
        },

    },

    async mounted() {
        this.callLoading(true);
        const { data } = await BankService.index();
        this.banks = this.getBankData(data);
        this.bankAccounts = this.banks;
        this.callLoading(false);
    },

    methods: {
        async uploadFile() {
            const { request } = this;

            const isConfirmed = await this.confirmAnAction('Você deseja mesmo importar esse arquivo?');
            if (!isConfirmed) return;

            callLoading(true);
            const status = await this.uploadOfx(request);
            callLoading(false);

            if (status === 200) {
                this.modalSuccess('Arquivo importado com sucesso');
            } else if (status === 406) {
                this.modalError('Arquivo já foi importado');
            } else if (status === 415) {
                this.modalError('Arquivo sem transações');
            } else {
                this.modalError('Erro ao importar o arquivo');
            }
        },

        async uploadOfx({
            file,
            username,
            account,
            bank,
        }) {
            return OfxService.import({
                OfxFile: file,
                username,
                account,
                bank,
            });
        },
    },
};

</script>
